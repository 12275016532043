import React from 'react';
import PropTypes from 'prop-types';
import { PhotoGalleryInner } from './PhotoGalleryInner';
import { Transporter } from '../../components/Transporter';

export const PhotoGalleryWrapper = ({ content }) => (
  <>
    <PhotoGalleryInner content={content} isTransported={false} />
    <Transporter content={content} />
  </>
);

PhotoGalleryWrapper.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
